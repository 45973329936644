/* eslint-disable no-console */
import Cookie from 'js-cookie';

const VWO_FS_HANDOFF_COOKIE_NAME = 'vwo_fs_handoff';
const VWO_UUID_COOKIE_NAME = '_es_uuid';
const GTM_EVENT_NAME = 'VWO_FS_Test';

document.addEventListener('DOMContentLoaded', () => {
    // Get full-stack cookie data set by the server
    const vwoFsHandoff = Cookie.get(VWO_FS_HANDOFF_COOKIE_NAME);
    if (!vwoFsHandoff) {
        return;
    }

    // Read cookie data into the data-layer for GTM
    const testParams = new URLSearchParams(decodeURIComponent(vwoFsHandoff));
    const vwoFsHandoffData = ['campaign_id', 'campaign_key', 'variation_id', 'variation_name']
        .reduce((acc, paramName) => {
            if (testParams.has(paramName)) {
                acc[paramName] = testParams.get(paramName);
                if (paramName.endsWith('_id') && acc[paramName]) {
                    acc[paramName] = Number(acc[paramName]);
                }
            }
            return acc;
        }, {});
    const uuid = Cookie.get(VWO_UUID_COOKIE_NAME);
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
        event: GTM_EVENT_NAME,
        uuid,
        ...vwoFsHandoffData,
    });
});
